.card-height {
    height: 350px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    transform: scale(1, 1);
    transition: transform 0.4s;
  }
  .card-height:hover{
      transform: scale(1.01, 1.01);
  }
  .ft {
    background: rgba(255, 255, 255, 0.01);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
  .swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction{
    bottom: 10px !important;
  }
  .swiper{
    padding-bottom: 35px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
.delete-icon{
  position: absolute;
  right:0px;
  top:0px;
  padding: 20px;
  background: red;
  border-radius: 0 0 0 30px;
  cursor: pointer;
  color:white
}