@media only screen and (max-width: 600px) {
    .propDesc{
        display:flex !important;
        flex-direction: column !important;
        align-items: center !important;
    }
    .propDesc,.feature,.form{
        width: 100% !important;
    }
    .feature{
        width: 100% !important;
    }
  }